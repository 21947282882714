<template>
  <router-view />
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  name: "App",
  data() {
    return {
      pageTitle: "",
    };
  },
  computed: {
    IsHome() {
      console.log("当前", this.pageTitle);
      return this.pageTitle == "Home";
    },
  },
  watch: {
    //监听页面标题变化（计算属性监听document.title总是不更新）
    $route() {
      this.pageTitle = this.$route.name;
      console.log(this.pageTitle);
    },
  },
  components: {},
  created() {
    this.appLogin(true);
    console.log("获取登录信息Index");
  },
  methods: {
    backPage() {
      console.log("上一级");
      this.$router.back();
    },
    appLogin(requestApi = false) {
      let that = this;
      this.$userInfo
        .getUserInfo(requestApi)
        .then((uInfo) => {
          console.log("登录成功：", uInfo);
          if (!uInfo) {
            //登录失败
            ElMessage({
              showClose: true,
              message: "登录失败，请使用微信浏览器浏览器打开",
              type: "error",
            });
          } else {
            that.$store.commit("setUser", uInfo);
          }
        })
        .catch(() => {
          ElMessage.error("微信登录失败");
        });
    },
    connectWebSocket() {
      if (window.httpConfig.P_WSS) {
        if (this.$store.state.selectedPatient) {
          let msg = JSON.stringify({
            openId: this.$store.state.selectedPatient.OpenId,
          });
          this.$socket.send(msg);
        }
      }
    },
  },
};
</script>

<style lang="less">
@import "/public/css/ViewGrid.less";
</style>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.footer {
  height: 50px;
}
* {
  margin: 0px;
  padding: 0px;
}
.userInfoCard {
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  text-align: left;
  height: 100px;
  background-color: #c6e2ff;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
