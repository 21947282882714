import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import Router from "./routers/indexRouter";
import apiRequest from "./utils/api";
import userInfo from "./utils/userInfo";
import WebSocketPlugin from "./plugins/WebSocketPlugin";
import store from "./store/index";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import eventBus from "vue3-eventbus";
import locale from "element-plus/lib/locale/lang/zh-cn";

const app = createApp(App);
app.use(ElementPlus, { locale });
app.use(store);
//插件注册
app.config.globalProperties.$apiRequest = apiRequest;
app.config.globalProperties.$userInfo = userInfo;

window.$store = store; //全局通过window.$store可访问

app.use(eventBus);
app.use(Router);
//注册ele icon图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
let wssurl = "";
if (process.env.NODE_ENV == "development") {
  wssurl = window.httpConfig.P_WSSIP_DEV; //"http://192.168.1.166:9994/";
} else if (process.env.NODE_ENV == "debug") {
  wssurl = window.httpConfig.P_WSSIP_DEBUG; //"http://192.168.1.166:9994/";
} else if (process.env.NODE_ENV == "production") {
  wssurl = window.httpConfig.P_WSSIP; //  ("http://121.18.207.82:9010/");
}

if (window.httpConfig.P_WSS) {
  // 注册 WebSocket 插件
  app.use(WebSocketPlugin, {
    url: wssurl,
    reconnectInterval: 5000,
  });
}
app.mount("#app");
