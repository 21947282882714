import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      userInfo: {},
      bannerImg: [],
      sysAuth: [],
      hospitalInfo: null,
    };
  },
  mutations: {
    setUser(state, user) {
      localStorage.setItem("userInfo", JSON.stringify(user));
      state.userInfo = user;
    },
    setBannerImg(state, bannerImg) {
      state.bannerImg = bannerImg;
    },
    setSysAuth(state, sysAuth) {
      state.sysAuth = sysAuth;
    },
    setHospitalInfo(state, hospitalInfo) {
      state.hospitalInfo = hospitalInfo;
    },
  },
  actions: {
    // 可以在这里定义异步操作，然后提交 mutations
  },
  getters: {
    // 可以在这里定义派生状态，如计算属性
  },
});

export default store;
