import { createRouter, createWebHashHistory } from "vue-router";

// 创建路由实例
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Index",
      component: () => import("@/views/Index"),
    },
    {
      path: "/DailyFlow",
      name: "DailyFlow",
      component: () => import("@/views/statistics/DailyFlow"),
    },
    {
      path: "/SerchReport",
      name: "SerchReport",
      component: () => import("@/views/userViews/SerchReport"),
    },
    {
      path: "/SerchSqd",
      name: "SerchSqd",
      component: () => import("@/views/userViews/xxtj/SerchSqd"),
    },

    {
      path: "/:catchAll(.*)",
      component: () => import("@/views/errorView/404"), // 404 页面的组件路径
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
