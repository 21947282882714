// import { ElMessage } from "element-plus";
import apiRequest from "./api";

let s = {
  data() {
    return {
      code: "",
      userAgent: "",
    };
  },
  getCodeFromUrl() {
    const searchParams = new URLSearchParams(window.location.search);
    this.code = searchParams.get("code");
    return searchParams.get("code");
  },
  login() {
    let res = new Promise((resolve, reject) => {
      //调用登录
      //ElMessage.info("登录中...");
      let code = this.getCodeFromUrl();
      console.log("登录中....", code);
      var pd = true; //是否需要用openid登录
      // if (code && code != null)
      {
        //微信登录
        apiRequest
          .post("/api/WeChatAPI/Login?code=" + code, {}, "登录中")
          .then((res) => {
            console.log(res);
            if (res.status) {
              const uInfo = res.data;
              localStorage.setItem("userInfo", JSON.stringify(uInfo));
              window.$store.commit("setUser", uInfo);
              pd = false;
              return resolve(uInfo);
            } else {
              //如果登录失败 则使用openid登录
            }
          })
          .finally(() => {
            if (pd && localStorage.getItem("userInfo")) {
              apiRequest
                .post("/api/WeChatAPI/GetLoginInfo", {}, true)
                .then((res) => {
                  if (res.status) {
                    const uInfo = res.data;
                    localStorage.setItem("userInfo", JSON.stringify(uInfo));
                    window.$store.commit("setUser", uInfo);
                    return resolve(uInfo);
                  } else {
                    reject();
                  }
                })
                .catch(() => {
                  reject();
                });
            }
          });
        //支付宝登录需要用到监听小程序推送 所以写在了app.vue页面
      }
    });
    return res;
  },
  getUserInfo(requestApi = false) {
    let res = new Promise((resolve, reject) => {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo && !requestApi) {
        resolve(userInfo);
      } else {
        this.login()
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
    return res;
  },
  getBrowser() {
    //获取浏览器类型
    console.log(navigator.userAgent.toLowerCase());
    this.userAgent = navigator.userAgent.toLowerCase();
    let browser = "";
    if (/micromessenger/.test(this.userAgent)) {
      browser = "weChat";
    } else if (/alipayclient/.test(this.userAgent)) {
      browser = "aliPay";
    } else {
      browser = "unknow";
    }
    localStorage.setItem("browserType", browser);
    return browser;
  },
};
export default s;
