import axios from "axios";
import { ElLoading, ElMessage } from "element-plus";

let loadingInstance;
let loadingStatus = false;
console.log("加载API配置", window.httpConfig);
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = window.httpConfig.P_SERVERIP_DEV; //"http://192.168.1.166:9994/";
} else if (process.env.NODE_ENV == "debug") {
  axios.defaults.baseURL = window.httpConfig.P_SERVERIP_DEBUG; //"http://192.168.1.166:9994/";
} else if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = window.httpConfig.P_SERVERIP; //("http://121.18.207.82:9010/");
}
if (!axios.defaults.baseURL.endsWith("/")) {
  axios.defaults.baseURL += "/";
}
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    config.headers["SystemType"] = "SH";
    if (localStorage.getItem("userInfo")) {
      config.headers["openid"] = JSON.parse(
        localStorage.getItem("userInfo")
      ).openid;
    }
    let getBrowser = localStorage.getItem("browserType");
    config.headers["browser"] = getBrowser;
    if (getBrowser == "aliPay") {
      if (!config.headers["payType"]) {
        config.headers["payType"] = "aliPay";
      }
    }
    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 在接收到响应后可以进行一些公共的处理，如解析响应数据等
    return response.data;
  },
  (error) => {
    // 判断服务器返回的状态码，可以根据实际情况进行调整
    if (error.response && error.response.status === 500) {
      // 使用 Element Plus 的 Message 组件进行提示
      ElMessage("服务器出错，请稍后重试");
    }
    if (error.response && error.response.status === 404) {
      // 使用 Element Plus 的 Message 组件进行提示
      ElMessage("未找到请求地址");
    }
    // 处理响应错误
    return Promise.reject(error);
  }
);

function showLoading(loading) {
  let text = "加载中";
  if (typeof loading === "string") {
    text = loading;
  }
  if (loading && !loadingStatus) {
    const options = {
      fullscreen: true, // 是否全屏显示
      lock: true, // 是否锁定页面交互
      text: text, // 加载框的文字
      background: "rgba(0, 0, 0, 0.7)", // 遮罩背景色
    };
    loadingInstance = ElLoading.service(options);
    loadingStatus = true;
  }
}

function hideLoading() {
  if (loadingStatus && loadingInstance) {
    loadingInstance.close();
    loadingStatus = false;
  }
}

// 封装通用的请求方法
const request = {
  get(url, data, loading, config) {
    showLoading(loading);
    return axios
      .get(url, { params: data }, config)
      .finally(() => hideLoading()); // 请求完成后关闭加载框
  },
  post(url, data, loading, config) {
    showLoading(loading);
    return axios.post(url, data, config).finally(() => hideLoading()); // 请求完成后关闭加载框
  },
  baseURL: axios.defaults.baseURL,
};
export default request;
